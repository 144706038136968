
.landing {
    background: linear-gradient(-45deg, #fbffed, #efffed, #edfffe, #edefff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.landing h1 {
	width: 11ch;
	overflow: hidden;
	border-right: solid grey;
	white-space: nowrap;
	margin: 0 auto; 
	font-family: monospace;
	animation: 
	  typing 1s steps(11),
	  blink .5s step-end infinite alternate;
}

/* The typing effect */
@keyframes typing {

  	from { width: 0 }
}

/* The typewriter cursor effect */
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.landing h2 {
	font-size: 160%;
	width: 24ch;
	overflow: hidden;
	border-right: solid grey;
	white-space: nowrap;
	margin: 0 auto; 
	font-family: monospace;
	animation: 
	  typing 1.5s steps(22),
	  blink .5s step-end infinite alternate;
}

/* The typing effect */
@keyframes typing {

  	from { width: 0 }
}

/* The typewriter cursor effect */
@keyframes blink {
  50% {
    border-color: transparent
  }
}