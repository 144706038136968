.edit-transaction-amount {
  max-width: 120px !important;
}

.danger-color {
  color: #dc3545;
}

.btn-outline-light {
  color: #888888 !important;
}

.btn-outline-light:hover {
  color: #ffce67;
}

.category-label {
  font-size: xx-small;
  width: 3rem;
  margin: 2px 0 0;
}
