.inner-height {
  height: 100vh;
  height: calc(var(--ih, 1vh) * 100);
}

.truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
}

/* Hide number input spinner */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide number input spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.round {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
